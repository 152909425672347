<template>
  <table-content-hulling-facility
    :images="images"
    :list-hulling="listHulling"
    :filter-role="filterRole"
    :set-toggle-modal-add-edit-discard="setToggleModalAddEditDiscard"
    :get-toggle-modal-add-edit-discard="getToggleModalAddEditDiscard"
  />
</template>

<script>
import { mapMutations, mapGetters } from 'vuex'
import TableContentHullingFacility from '@/components/globals/table/facility/hulling-facility/index'

export default {
  components: {
    TableContentHullingFacility
  },
  props: {
    images: {
      required: true,
      type: String
    },
    listHulling: {
      required: true,
      type: Object
    },
    // eslint-disable-next-line vue/require-default-prop
    filterRole: {
      required: false,
      type: Object
    }
  },
  computed: {
    ...mapGetters({
      getToggleModalAddEditDiscard: 'hullingFacility/get_toggle_modal_add_edit_discard'
    })
  },
  methods: {
    ...mapMutations({
      setToggleModalAddEditDiscard: 'hullingFacility/SET_TOGGLE_MODAL_ADD_EDIT_DISCARD'
    })
  }
}
</script>
